<template>
  <div class="page1">
    <!--主体内容-->
    <div class="contenCorrectRecord">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.park_name')">
                <el-autocomplete
                  class="inline-input"
                  v-model.trim="modelvalue"
                  :fetch-suggestions="querySearchAsyncCar"
                  placeholder="请输入内容"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelectCar"
                ></el-autocomplete>
              </el-form-item>

              <!-- <el-form-item label="">
            <el-select v-model.trim="formInline.plateType" placeholder size="12" style="width:190px">
              <el-option label="修正前车牌" value="0" key="0"></el-option>
              <el-option label="修正后车牌" value="1" key="1"></el-option>
            </el-select>
          </el-form-item>-->

              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>

              <el-form-item :label="$t('searchModule.Correction_type')">
                <el-select
                  v-model.trim="formInline.type"
                  placeholder
                  size="12"
                  style="width: 190px"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    v-for="value in timeList"
                    :key="value.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Correction_time')">
            <el-date-picker
              v-model="valueDate"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>-->

              <el-form-item :label="$t('searchModule.Correction_time')">
                <!-- <el-date-picker
              v-model="date1"
              type="datetime"
              placeholder="选择日期"
              @change="setStartTime"
            ></el-date-picker>至
            <el-date-picker v-model="date2" type="datetime" placeholder="选择日期" @change="setEndTime"></el-date-picker> -->
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defaultDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  searchData(1);
                  page = 1;
                "
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                style="display: inline-block"
                :exportData="exportData"
                v-if="$route.meta.authority.button.export"
              ></exportFile>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB1">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            :index="indexMethod"
            width="70"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column label="操作" width="70">
            <template slot-scope="scope">
              <el-button type="text" @click="detailShow(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页器-->
      <div class="pagerWrapper">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>

      <el-dialog
        title="车牌详情"
        :visible.sync="dialogTableVisible"
        class="dialogClass"
      >
        <el-row>
          <el-col :span="6">
            <div class="bg-purple">车场</div>
          </el-col>
          <el-col :span="6">
            <div class="bg-purple-light">{{ tableDetail.parkName }}</div>
          </el-col>
          <el-col :span="6">
            <div class="bg-purple">通道</div>
          </el-col>
          <el-col :span="6">
            <div class="bg-purple-light-last">
              {{ tableDetail.parkChannelName }}
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="bg-purple bg-purple-light-top">修正前车牌</div>
          </el-col>
          <el-col :span="6">
            <div class="bg-purple-light bg-purple-light-top">
              {{
                tableDetail.beforePlateNumber &&
                tableDetail.beforePlateNumber.indexOf("无") != -1
                  ? "无牌车"
                  : tableDetail.beforePlateNumber
              }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="bg-purple bg-purple-light-top">修正后车牌</div>
          </el-col>
          <el-col :span="6">
            <div class="bg-purple-light-last bg-purple-light-top">
              {{
                tableDetail.afterPlateNumber &&
                tableDetail.afterPlateNumber.indexOf("无") != -1
                  ? "无牌车"
                  : tableDetail.afterPlateNumber
              }}
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="bg-purple bg-purple-light-top">修改类型</div>
          </el-col>
          <el-col :span="6">
            <div
              class="bg-purple-light bg-purple-light-top"
              v-if="tableDetail.type == 0"
            >
              入场修改
            </div>
            <div
              class="bg-purple-light bg-purple-light-top"
              v-if="tableDetail.type == 1"
            >
              场中修改
            </div>
            <div
              class="bg-purple-light bg-purple-light-top"
              v-if="tableDetail.type == 2"
            >
              出场修改
            </div>
          </el-col>
          <el-col :span="6">
            <div class="bg-purple bg-purple-light-top"></div>
          </el-col>
          <el-col :span="6">
            <div class="bg-purple-light-last bg-purple-light-top"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" v-for="value in oprNumPic" :key="value.imageType">
            <div class="picBox">
              <img
                :src="value.picUrl"
                width="60%"
                height="50%"
                @click="showBigPic(value.picUrl)"
              />
            </div>
            <div class="pciTile">
              {{
                value.imageType == 1
                  ? "全景照片"
                  : value.imageType == 2
                  ? "车辆图"
                  : value.imageType == 3
                  ? "车牌特写图"
                  : ""
              }}
            </div>
          </el-col>
          <!-- <el-col :span="12">
            <div class="picBox">
              <img
                src="../../assets/img/park.jpg"
                width="60%"
                height="50%"
                @click="showBigPic(value.picUrl)"
              />
            </div>
            <div class="pciTile">车牌特写图</div>
          </el-col>-->
        </el-row>
        <div class="btn">
          <el-button @click="consoleDetail()">关闭</el-button>
        </div>
      </el-dialog>
    </div>
    <transition name="fade">
      <div
        class="mask"
        v-show="bigPic"
        :style="{ 'z-index': '10000', 'background-image': picUrl }"
        @click="bigPic = false"
      ></div>
    </transition>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import { dateFormat, exportExcel, exportExcelNew } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";
export default {
  name: "correctRecord",
  data() {
    let date1 = new Date();
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    let startTime = new Date(date1.getTime() - 3600 * 1000 * 24 * 30);
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    let endTime = date;
    return {
      defaultDate: [],
      picUrl: "",
      bigPic: false,
      oprNumPic: [],
      exportData: {},
      date1: startTime,
      date2: endTime,
      modelvalue: "",
      plateNumber: "",
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "beforePlateNumber",
          label: "修正前车牌",
          formatter: (row) => {
            return row.beforePlateNumber &&
              row.beforePlateNumber.indexOf("无") != -1
              ? "无牌车"
              : row.beforePlateNumber;
          },
          width: "",
        },
        {
          prop: "afterPlateNumber",
          formatter: (row) => {
            return row.afterPlateNumber &&
              row.afterPlateNumber.indexOf("无") != -1
              ? "无牌车"
              : row.afterPlateNumber;
          },
          label: "修正后车牌",
          width: "",
        },
        {
          prop: "strCorrectTime",
          label: "修正时间",
          width: "",
          // formatter: row => {
          //   return this.payTypeList.filter(v => v.code === row.parkType)[0]
          //     .desc;
          // }
        },
        {
          prop: "type",
          label: "修正类型",
          width: "",
          formatter: (row) => {
            if (row.type == 0) {
              return "入场修改";
            } else if (row.type == 1) {
              return "场中修改";
            } else if (row.type == 2) {
              return "出场修改";
            }
          },
        },
        {
          prop: "operator",
          label: this.$t("list.Operator"),
          width: "120",
          // formatter: row => {
          //   if (/无/g.test(row.plateNumber)) {
          //     return "无牌车";
          //   } else {
          //     return row.plateNumber;
          //   }
          // }
        },
      ],
      tableDetail: [],
      valueDate: "",
      formInline: {
        // parkName: "",
        type: "",
        // plateNumber: "",
        parkId: "",
        correctStartTime: "",
        correctEndTime: "",
        carId: "",
        // plateType: "0",
        name: `车牌修正记录`,
      },
      timeList: [
        {
          code: "0",
          desc: "入场修改",
        },
        {
          code: "1",
          desc: "场中修改",
        },
        {
          code: "2",
          desc: "出场修改",
        },
      ],
      tableData: [],
      pageSize: 15,
      page: 1,
      loading: false,
      total: 0,
      dialogTableVisible: false,
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
      }
    },
  },
  methods: {
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.correctStartTime = timeArr[0];
      this.formInline.correctEndTime = timeArr[1];
    },
    showBigPic(url) {
      if (!url) {
        return false;
      }
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
    setStartTime(value) {
      this.formInline.correctStartTime = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.correctEndTime = dateFormat(value);
    },
    showLog() {
      if (this.formInline.correctStartTime && this.formInline.correctEndTime) {
        let time =
          new Date(this.formInline.correctEndTime) -
          new Date(this.formInline.correctStartTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    indexMethod(index) {
      return index + 1;
    },

    // 首页列表查询
    searchData(tag) {
      if (tag == 1) {
        this.page = 1;
      }
      let flag = this.showLog();
      if (!flag) {
        return;
      }
      this.$axios
        .get("/acb/2.0/bacb/plateCorrectRecord/list", {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            type: this.formInline.type,
            correctStartTime: this.formInline.correctStartTime,
            correctEndTime: this.formInline.correctEndTime,
            parkId: this.formInline.parkId,

            carId: this.formInline.carId,
            // plateType: this.formInline.plateType
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.tableData = [];
            this.total = 0;
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    searchDetail(dataRow) {
      this.$axios
        .get("/acb/2.0/bacb/plateCorrectRecord/parkRecord/detail", {
          data: {
            plateCorrectRecordId: dataRow.plateCorrectRecordId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableDetail = res.value;
            this.getPic(
              res.value.entryOprNum,
              res.value.exitOprNum,
              res.value.type
            );
          } else {
            this.tableDetail = [];
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum, oprNumType) {
      this.oprNumPic = [];
      if (oprNumType == 0) {
        if (entryOprNum) {
          this.$axios
            .get("/acb/2.0/bacb/parkRecord/picture/" + entryOprNum)
            .then((res) => {
              if (res.state == 0) {
                this.oprNumPic = res.value;
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      }
      if (oprNumType == 2) {
        if (exitOprNum) {
          this.$axios
            .get("/acb/2.0/bacb/parkRecord/picture/" + exitOprNum)
            .then((res) => {
              if (res.state == 0) {
                this.oprNumPic = res.value;
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      }
    },
    detailShow(dataRow) {
      this.dialogTableVisible = true;
      this.searchDetail(dataRow);
    },

    consoleDetail() {
      this.dialogTableVisible = false;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    exportFile() {
      let opt = {};
      opt = {
        pageNum: this.page,
        pageSize: this.pageSize,
        type: this.formInline.type,
        correctStartTime:
          this.valueDate == null || this.valueDate == ""
            ? ""
            : dateFormat(this.valueDate[0], "yyyy-MM-dd"),
        correctEndTime:
          this.valueDate == null || this.valueDate == ""
            ? ""
            : dateFormat(this.valueDate[1], "yyyy-MM-dd"),
        parkId: this.formInline.parkId,

        carId: this.formInline.carId,
      };

      // /acb/2.0/specialplate/export
      exportExcelNew(
        "/acb/2.0/bacb/plateCorrectRecord/exportRecord",
        opt,
        "get"
      );
    },
    querySearchAsync(queryString, cb) {
      this.formInline.plateNumber = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value);
          }
        });
    },
    querySearchAsyncCar(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/bacb/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: this.modelvalue,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.temParkData = res.value.list.length
            //   ? res.value.list[0]
            //   : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            //  this.parkData = res.value.list;
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.carId = item.carId;
      this.page = 1;
    },
    handleSelectCar(item) {
      this.page = 1;
      this.formInline.parkId = item.parkId;
    },
  },
  components: {
    exportFile,
    timeRangePick,
  },
  created() {
    this.defaultDate = [
      this.dataTimeRest() + " 00:00:00",
      this.dataTimeRest() + " 23:59:59",
    ];
    this.formInline.correctStartTime = this.defaultDate[0];
    this.formInline.correctEndTime = this.defaultDate[1];
  },
  mounted() {
    this.exportData = {
      url: "/acb/2.0/bacb/plateCorrectRecord/exportRecord",
      methods: "post",
      data: this.formInline,
      // data: {
      //   parkId: this.formInline.parkId,
      //   carId: this.formInline.carId,
      //   correctStartTime: dateFormat(this.formInline.startTime),
      //   correctEndTime: dateFormat(this.formInline.endTime),
      //   type: this.formInline.type,
      //   operatorId: this.formInline.operatorId,
      //   name: `车牌修正记录`,
      // },
    };
    this.searchData();
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  // height: 35px;
}

.contenCorrectRecord {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}
</style>
<style lang="stylus"scoped>
.contenCorrectRecord .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}

.contenCorrectRecord .el-dialog {
  width: 55%;
}

.contenCorrectRecord {
  .bg-purple {
    background-color: #e5e9f2;
    height: 30px;
    line-height: 30px;
    border: 1px solid;
    text-align: center;
  }

  .bg-purple-light {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    text-align: center;
  }

  .bg-purple-light-last {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    text-align: center;
    border-right: 1px solid;
  }

  .bg-purple-light-top {
    border-top: 0px solid;
  }

  .picBox {
    padding-top: 50px;
    box-sizing: border-box;
    text-align: center;
  }

  .pciTile {
    text-align: center;
    margin-top: 15px;
  }

  .btn {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
}

/deep/ .dialogClass .el-dialog__header {
  padding: 0 12px;
  // background-color: #3F4A56;
  height: 52px;
  line-height: 50px;
  border-radius: 3px 3px 0 0;
}

/deep/.dialogClass .el-dialog__title {
  font-size: 16px;
  font-weight: 700;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #323233;
}

/deep/.dialogClass .el-dialog__headerbtn .el-dialog__close {
  color: #323233;
  transform: translateY(-50%);
}
</style>
